export default class BokeManager {
    static add(data) {
        return new Promise(((resolve, reject) => {
            data.status = data.status ? 1 : 0
            window.go({
                url: "/boke/adminBoke/add", data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static list(data) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/adminBoke/list", data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static getBokeById(id) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/adminBoke/getBokeById", data: {
                    id: id
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static getCategory() {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/adminBoke/getCategory", data: {}
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static deleteByIds(ids) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/adminBoke/deleteByIds", data: {
                    ids: ids
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }

    static update(data) {
        return new Promise(((resolve, reject) => {
            data.status = data.status ? 1 : 0
            window.go({
                url: "/boke/adminBoke/update", data: data
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }
    static updateStatus(ids, status) {
        return new Promise(((resolve, reject) => {
            window.go({
                url: "/boke/adminBoke/updateStatus", data: {
                    ids: ids, status: status
                }
            }).then(res => {
                resolve(res.result)
            }, error => {
                reject(error)
            })
        }))
    }
}
