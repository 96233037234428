<template>
    <div class="container">
        <el-form class="form" :model="formData" ref="form">
            <el-form-item label="标题" prop="title">
                <el-input type="textarea" v-model="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-switch v-model="formData.status"></el-switch>
            </el-form-item>
            <el-form-item label="类别" prop="category">
                <el-select
                    v-model="formData.category"
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请选择文章类别">
                    <el-option
                        v-for="item in categoryList"
                        :key="item.category"
                        :label="item.category"
                        :value="item.category">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" prop="content">
                <div style="border: 1px solid #ccc;">
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    >

                    </Toolbar>
                    <Editor
                        style="height: 1000px; overflow-y: hidden;"
                        v-model="formData.content"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onChange="onChange"
                        @onCreated="onCreated"
                    />
                </div>
            </el-form-item>
        </el-form>
        <div class="toolBtn">
            <el-button v-if="showDelete" type="primary" @click="updateForm()">更新</el-button>
            <el-button v-else type="primary" @click="submitForm()">提交</el-button>
            <el-button v-show="showDelete" type="primary" @click="deleteForm()">删除</el-button>
        </div>
        <el-dialog title="上传视频" :visible.sync="dialogFormVisible">
            <el-descriptions class="margin-top" :column="1" size="small" border>
                <el-descriptions-item :labelStyle="{width: '120px'}">
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        视频文件
                    </template>
                    <el-upload
                        class="upload-demo"
                        :action="config.baseURL + 'boke/fileBoke/upload'"
                        multiple
                        :limit="1"
                        :show-file-list="false"
                        :on-success="handleUploadVideoSuccess"
                    >
                        <span v-if="videoInfo!==null"
                              style="margin-right: 15px">{{ videoInfo.newName + "." + videoInfo.extension }}</span>
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        封面
                    </template>
                    <el-upload
                        class="avatar-uploader"
                        :action="config.baseURL + 'boke/fileBoke/upload'"
                        :show-file-list="false"
                        :on-success="handleUploadImageSuccess"
                    >
                        <img style="border-radius: 10px;width: 178px;height: 178px" v-if="videoImage!==null"
                             :src="videoImage.relativePath"
                             class="avatar">
                        <div v-else
                             style="border-radius: 10px;border: 1px #ddd dashed;width: 178px;height: 178px;display: block;justify-content: center;align-items: center;padding-top: 80px;">
                            <i class="el-icon-plus"></i>
                        </div>
                    </el-upload>
                </el-descriptions-item>
            </el-descriptions>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialogFormVisible">取 消</el-button>
                <el-button type="primary" @click="commitDialogFormVisible">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";

import config from "../plugins/config";
import {mapState} from "vuex";
import BokeManager from "@/views/BokeManager";
import FileManager from "@/views/FileManager";

export default {
    name: "Edit",
    components: {
        Editor,
        Toolbar
    },
    data() {
        return {
            videoImage: null,
            videoInfo: null,
            config: config,
            dialogFormVisible: false,
            formLabelWidth: "150",
            form: {
                width: 0,
                height: 0
            },
            formData: {
                id: "",
                title: '',
                status: true,
                category: '',
                content: '',
                imageIds: "",
                videoIds: ""
            },
            uploadFilesArr: [],
            uploadFilesEnable: true,
            categoryList: [],
            showDelete: false,
            payVoucherDialog: false,
            imageList: [],
            imageObj: null,
            timer: null,
            editor: null,
            html: '',
            toolbarConfig: {
                toolbarKeys: [
                    'bold',
                    'underline',
                    'italic',
                    'through',
                    'color',
                    'bgColor',
                    'fontSize',
                    'indent',
                    'delIndent',
                    'justifyLeft',
                    'justifyRight',
                    'justifyCenter',
                    'justifyJustify',
                    'lineHeight',
                    'divider',
                    'blockquote',
                    'headerSelect',
                    'bulletedList',
                    'numberedList',
                    'insertTable',
                    'tableFullWidth',
                    'uploadVideo',
                    'uploadImage',
                    'editImage',
                    'viewImageLink',
                    'imageWidth30',
                    'imageWidth50',
                    'imageWidth100',
                    'insertLink',
                    'editLink',
                    'unLink',
                    'viewLink',
                    'codeBlock',
                    "codeSelectLang",
                    "fullScreen"
                ]
            },
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        timeout: 15 * 1000,
                        allowedFileTypes: ['image/*'],
                        maxFileSize: 100 * 1024 * 1024,
                        fieldName: "file",
                        server: config.baseURL + 'boke/fileBoke/upload',
                        // 单个文件上传成功之后
                        onSuccess(file, res) {
                            console.log(`${file.name} 上传成功`, res)
                        },
                        // 单个文件上传失败
                        onFailed(file, res) {
                            console.log(`${file.name} 上传失败`, res)
                        },
                        // 上传错误，或者触发 timeout 超时
                        onError(file, err, res) {
                            console.log(`${file.name} 上传出错`, err, res)
                        },
                        customInsert: (res, insertFn) => {
                            // res 即服务端的返回结果
                            console.log(res);
                            if (res.code === 200) {
                                let image = res.result[0]
                                insertFn(image.relativePath, "", "")
                                this.uploadFilesArr.push(image)
                            }
                        }
                    },
                    uploadVideo: {
                        timeout: 15 * 1000,
                        allowedFileTypes: ['video/*'],
                        maxFileSize: 100 * 1024 * 1024,
                        fieldName: "file",
                        server: config.baseURL + 'boke/fileBoke/upload',
                        // 单个文件上传成功之后
                        onSuccess(file, res) {
                            console.log(`${file.name} 上传成功`, res)
                        },
                        // 单个文件上传失败
                        onFailed(file, res) {
                            console.log(`${file.name} 上传失败`, res)
                        },
                        // 上传错误，或者触发 timeout 超时
                        onError(file, err, res) {
                            console.log(`${file.name} 上传出错`, err, res)
                        },
                        customBrowseAndUpload: (insertFn) => {
                            this.dialogFormVisible = true;
                            this.insertFn = insertFn
                        }
                    }
                }
            },
            mode: 'simple', // or 'simple',
            insertFn: null
        }
    },
    computed: {
        ...mapState([
            "screenHeight"
        ])
    },
    created() {
        if (this.$route.query.id) {
            this.showDelete = true;
            this.loadInfo()
        }
        this.loadCategory()
    },
    methods: {
        cancelDialogFormVisible: function () {
            this.dialogFormVisible = false;
            this.videoInfo = null;
            this.videoImage = null
            this.insertFn = null
        },
        commitDialogFormVisible: function () {
            this.insertFn(this.videoInfo.relativePath, this.videoImage.relativePath)
            this.dialogFormVisible = false;
            this.videoInfo = null;
            this.videoImage = null
            this.insertFn = null
        },
        handleUploadVideoSuccess: function (res) {
            if (res.code === 200) {
                this.videoInfo = res.result[0]
                this.uploadFilesArr.push(this.videoInfo)
            } else {
                this.$message.error(res.result)
            }
        },
        handleUploadImageSuccess: function (res) {
            if (res.code === 200) {
                this.videoImage = res.result[0]
                this.uploadFilesArr.push(this.videoImage)
            } else {
                this.$message.error(res.result)
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            console.log(this.editor.getAllMenuKeys())
        },
        onChange() {
            if (this.uploadFilesEnable && this.$route.query.id) {
                this.uploadFilesEnable = false;
                FileManager.getFilesByNewNames(this.getAllFileNewName().join(",")).then(res => {
                    this.uploadFilesArr = res;
                })
            }
        },
        getAllFileNewName() {
            return this.editor.getElemsByType("image").map(item => {
                return item.src.split("/")[item.src.split("/").length - 1].split(".")[0];
            }).concat(this.editor.getElemsByType("video").map(item => {
                return item.src.split("/")[item.src.split("/").length - 1].split(".")[0];
            })).concat(this.editor.getElemsByType("video").filter(item => {
                return item.poster.length > 0;
            }).map(item => {
                return item.poster.split("/")[item.src.split("/").length - 1].split(".")[0];
            }));
        },
        getImageFileId() {
            let image_names = this.editor.getElemsByType("image").map(item => {
                return item.src.split("/")[item.src.split("/").length - 1].split(".")[0];
            })
            return this.uploadFilesArr.filter((item) => {
                return image_names.indexOf(item.newName) !== -1;
            }).map(item => {
                return item.id
            });
        },
        getVideoFileId() {
            let video_names = this.editor.getElemsByType("video").map(item => {
                return item.src.split("/")[item.src.split("/").length - 1].split(".")[0];
            }).concat(this.editor.getElemsByType("video").filter(item => {
                return item.poster.length > 0;
            }).map(item => {
                return item.poster.split("/")[item.src.split("/").length - 1].split(".")[0];
            }))
            return this.uploadFilesArr.filter((item) => {
                return video_names.indexOf(item.newName) !== -1;
            }).map(item => {
                return item.id
            });
        },
        loadCategory() {
            BokeManager.getCategory().then(res => {
                this.categoryList = res;
            })
        },
        loadInfo() {
            this.loadCategory()
            BokeManager.getBokeById(this.$route.query.id).then(res => {
                this.uploadFilesEnable = true
                this.formData = res;
                this.formData.status = this.formData.status === 1
            })
        },
        updateForm() {
            let fileList = this.getAllFileNewName()
            let file_remove = this.uploadFilesArr.filter((item) => {
                return fileList.indexOf(item.newName) === -1;
            }).map(item => {
                return item.id;
            })
            FileManager.deleteByIds(file_remove.join(",")).then(() => {
                this.formData.imageIds = this.getImageFileId().join(",")
                this.formData.videoIds = this.getVideoFileId().join(",")
                BokeManager.update(this.formData).then(() => {
                    this.$notify({
                        message: "更新成功!",
                        type: 'success'
                    })
                    this.loadInfo()
                })
            })
        },
        deleteForm() {
            BokeManager.deleteByIds(this.$route.query.id).then(() => {
                let file_remove = this.uploadFilesArr.map((item) => {
                    return item.id;
                })
                FileManager.deleteByIds(file_remove.join(",")).then(() => {
                    this.$notify({
                        message: "删除成功!",
                        type: 'success'
                    })
                    this.$router.push({
                        path: "/bokeList"
                    })
                })
            })
        },
        submitForm() {
            let fileList = this.getAllFileNewName()
            let file_remove = this.uploadFilesArr.filter((item) => {
                return fileList.indexOf(item.newName) === -1;
            }).map(item => {
                return item.id;
            })
            FileManager.deleteByIds(file_remove.join(",")).then(() => {
                this.formData.imageIds = this.getImageFileId().join(",")
                this.formData.videoIds = this.getVideoFileId().join(",")
                BokeManager.add(this.formData).then(() => {
                    this.$notify({
                        message: "提交成功!",
                        type: 'success'
                    })
                    this.editor.clear()
                    this.$refs['form'].resetFields();
                    this.$router.push({
                        path: "/bokeList"
                    })
                })
            })
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style lang="scss" scoped>

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: white;
    overflow: scroll;

    .form {
        width: 100%;
        text-align: left;
        margin-top: 15px;
    }

    .edit {
        width: 100%;
        text-align: left;
    }

    .toolBtn {
        position: fixed;
        top: 80px;
        right: 100px;
    }
}
</style>
