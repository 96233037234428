<template>
    <div class="login_container">
        <div class="login_form">
            <div class="login_form_title">笔记管理</div>
            <div class="login_form_list">
                <div class="login_form_item">
                    <el-input class="login_form_item_ip" placeholder="请输入账号" v-model="account"
                              clearable></el-input>
                </div>
                <div class="login_form_item">
                    <el-input class="login_form_item_ip" placeholder="请输入密码" v-model="password" clearable
                              show-password></el-input>
                </div>
                <div class=login_form_btn>
                    <el-button class="login_form_btn_bt" type="primary" @click="loginAction">登录</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Login",
    data() {
        return {
            account: "",
            password: ""
        }
    },
    methods: {
        loginAction: function () {
            if (this.account === "admin" && this.password === "lys123456") {
                this.$router.push({
                    path: "/index"
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login_container {
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;

    .login_form {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        padding: 30px 30px 40px 30px;

        .login_form_title {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
        }

        .login_form_list {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .login_form_item {
                width: 100%;
                margin-top: 15px;

                .login_form_item_ip {
                    width: 100%;
                }
            }

            .login_form_btn {
                width: 100%;
                margin-top: 40px;

                .login_form_btn_bt {
                    width: 100%;
                }

                .login_form_btn_register {
                    margin-top: 25px;
                    cursor: pointer;
                    color: cornflowerblue;
                    font-size: 13px;
                }
            }
        }
    }
}
</style>
